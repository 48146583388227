<template>
  <v-card>
    <v-card-title>{{ user.displayName }}</v-card-title>
    <v-card-subtitle>{{ user.email }}</v-card-subtitle>
  </v-card>
</template>

<script>
export default {
    name: 'UserProfile',
    props: ['user']
}
</script>